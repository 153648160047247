<template>
  <div>
    <img class="screenSize" :src="`/Images/Warhall_userhome_banner.png`" />
    <div class="padding" style="font-family: Arial, sans-serif;">
      Hi, {{ this.$store.state.username }}
      <span style="float: right">
        {{ getProStatus }}
        <button @click.prevent="redirectToStore" v-show="showUpgradeButton"> Upgrade to Pro </button></span
      >
    </div>
    <div class="padding">
      <div class="buttons">
        <button @click.prevent="redirectToStore">Warhall Store</button>
        <purchases />
        <games />
        <!-- <allgames /> -->
      </div>

      <p><router-link :to="{ name: 'redeemcode' }" class="spacing"> Redeem code </router-link></p>
      <p><router-link :to="{ name: 'changepassword' }" class="spacing"> Change password </router-link></p>
      <br />
    </div>
    <!-- <p><button @click.prevent="loadgames"> My Games </button></p>
          <p><button @click.prevent="loaditems"> My Items </button></p>
          <p><button @click.prevent="loadpurchases"> My purchases </button></p>
          <table v-show="tableIndex == 1">
            <game v-for="(game, n) in db.games" :key="`game${n}`" :game="game"></game>
          </table>
          <table v-show="tableIndex == 2">
            <gameitem v-for="(item, n) in db.items" :key="`game${n}`" :item="item"></gameitem>
          </table>
          <table v-show="tableIndex == 3">
            <gameitem v-for="(item, n) in db.purchases" :key="`game${n}`" :item="item"></gameitem>
          </table> -->
  </div>
</template>

<script>
import Purchases from "@/components/userhome/purchases.vue";
import Games from "@/components/userhome/games.vue";
//import AllGames from "@/components/userhome/allgames.vue";
//import firebase from "firebase/app";
//require("firebase/auth");
//import productdb from "@/components/store/db.json";
//import productdb from "@/components/userhome/gamesDB.json";
//import StoreSection from "@/components/store/section.vue";
//import Game from "@/components/userhome/game.vue";

export default {
  components: {
    //storesection: StoreSection,
    //game: Game,
    purchases: Purchases,
    games: Games,
    //allgames: AllGames,
  },

  name: "App",
  data() {
    return {
      email: null,
      username: null,
      showUpgradeButton: false,
      //db: productdb,
      //purchasedata: [],
      //tableIndex: 0,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.state.email == null) {
        vm.$router.push({ name: "login" });
      } else {
        vm.email = vm.$store.state.email;
      }
    });
  },

  computed: {
    getProStatus() {
      let response = this.$store.state.proStatus;
      let formattedDate = "Free";
      if (response != null) {
        formattedDate = "Pro: " + response;
      }
      return formattedDate;
    },
  },

  methods: {
    redirectToStore() {
      this.$router.push({ name: "store" });
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
}

.buttons {
  display: grid;
  grid-gap: 30px;
  justify-content: center;
  justify-items: center;
}

.spacing {
  padding: 10px;
}

.padding {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
  padding-right: 50px;
}
</style>
